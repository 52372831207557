
  <script>
    import {
      ArrowUpIcon,
      ArrowRightIcon
    } from 'vue-feather-icons';

    import Navbar from "@/components/navbar0";
    import Switcher from "@/components/switcher";
    import Footer from "@/components/footer";

    /**
     * Page-privacy component
     */
    export default {
      data() {
        return {}
      },
      components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon
      }
    }
  </script>
  <template>

    <div>
      <Navbar />

      <!-- Hero Start -->
      <section class="bg-half bg-light d-table w-100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
              <div class="page-next-level">
                <h4 class="title"> Согласие на оформление постоянных платежей </h4>
                <ul class="list-unstyled mt-4">
                  <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Дата публикации :</span> 3 Мая, 2022</li>
                </ul>
                <div class="page-next">
                  <nav aria-label="breadcrumb" class="d-inline-block">
                    <ul class="breadcrumb bg-white rounded shadow mb-0">
                      <li class="breadcrumb-item">
                        <router-link to="/">Skillrank</router-link>
                      </li>
                      <li class="breadcrumb-item"><a href="#">Документы</a></li>
                      <li class="breadcrumb-item active" aria-current="page">Согласие на оформление постоянных платежей</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!--end section-->
      <!-- Hero End -->

      <!-- Shape Start -->
      <div class="position-relative">
        <div class="shape overflow-hidden text-white">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
          </svg>
        </div>
      </div>
      <!--Shape End-->

      <!-- Start Privacy -->
      <section class="section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-9">
              <div class="card shadow rounded border-0">
                <div class="card-body">

                  <h4 class="title"> Политика в отношении обработки персональных данных </h4>

                  <h5 class="card-title">1. Общие положения </h5>

                  <p>1.1. Сумма сделки зависит от выбранного Пользователем тарифа и может составлять 1990 рублей в качестве постоянного и безакцептного платежа в месяц или 690 рублей рублей в качестве постоянного и безакцептного платежа в неделю.</p>
                  <p>1.2. В качестве расчетной валюты используются рубли.</p>
                  <p>1.3. Отмена постоянных и безакцептных платежей происходит путем на сайте в разделе "Личный кабинет" или переходом по ссылке в смс-уведомлении.</p>


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>


  </template>



<style scoped>

</style>